.contact {
  display: block;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 500px;
}

@media screen and (max-width: 1199px) {
  .contact {
    height: auto;
    width: 370px;
  }
}

@media screen and (max-width: 991px) {
  .contact {
    height: auto;
    width: 250px;
  }
}

@media screen and (max-width: 766px) {
  .contact {
    height: auto;
    width: 500px;
  }
}

@media screen and (max-width: 480px) {
  .contact {
    height: auto;
    width: 400px;
  }
}
