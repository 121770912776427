.bio {
  display: block;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 1125px;
}

@media screen and (max-width: 1199px) {
  .bio {
    height: auto;
    width: 600px;
  }
}

/* @media screen and (max-width: 991px) {
  .bio {
    height: auto;
    width: 600px;
  }
} */

@media screen and (max-width: 766px) {
  .bio {
    height: auto;
    width: 500px;
  }
}

@media screen and (max-width: 575px) {
  .bio {
    height: auto;
    width: 400px;
  }
}
