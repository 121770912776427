body {
  font-family: 'Noto Sans HK', sans-serif;
}

p {
  color: #515769;
}

a {
  text-decoration: none;
  color: #515769;
}

.link:after {
  background: #a6a7aa;
}

.portfolio-padding {
  padding-top: 50px;
}

.portfolio-link {
  float: right;
}

.link-padding {
  padding-top: 20px;
}

.btn-default-bg:hover,
.btn-default-bg:focus,
.btn-default-bg.focus {
  color: #fff;
  background: #515769;
  border-color: transparent;
}

.portfolio-img {
  max-width: 100% !important;
  max-height: 100% !important;
  display: block !important;
  margin-left: auto;
  margin-right: auto;
}
