.navbar-brand {
  font-size: 40px !important;
  font-weight: 700 !important;
  color: #515769 !important;
  padding: 20px 0px !important;
}

.navbar-brand:hover {
  color: #515769 !important;
}

.navbar-nav {
  margin-left: auto;
}

.nav-link {
  text-transform: uppercase !important;
  font-weight: 600 !important;
  color: #515769 !important;
}

.nav-link:hover {
  outline: 0;
  color: #999caa !important;
  text-decoration: none;
}

.nav-link.current-page {
  text-decoration: underline;
  text-underline-offset: 4px;
}
