html {
  overflow-x: hidden;
}

html,
html a,
body {
  -webkit-font-smoothing: antialiased;
}

body {
  position: relative;
  font-family: 'Noto Sans HK', sans-serif;
}

p {
  font-size: 15px;
  font-weight: 400;
  font-family: 'Noto Sans HK', sans-serif;
  color: #a6a7aa;
  line-height: 1.4;
  margin-bottom: 15px;
}

li,
li a {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Noto Sans HK', sans-serif;
  color: #515769;
}

a {
  font-family: 'Noto Sans HK', sans-serif;
  color: #81848f;
  outline: 0;
}

a:focus,
a:hover,
a:active {
  outline: 0;
  color: #999caa;
  text-decoration: none;
}

span {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Noto Sans HK', sans-serif;
  color: #bfc1c7;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  font-family: 'Noto Sans HK', sans-serif;
  color: #515769;
  line-height: 1.4;
  margin: 0 0 15px;
}

h1>a,
h2>a,
h3>a,
h4>a,
h5>a,
h6>a {
  color: #515769;
}

h1>a:hover,
h2>a:hover,
h3>a:hover,
h4>a:hover,
h5>a:hover,
h6>a:hover {
  color: #999caa;
  text-decoration: none;
}

h1>a:focus,
h2>a:focus,
h3>a:focus,
h4>a:focus,
h5>a:focus,
h6>a:focus {
  text-decoration: none;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 22px;
}

:active,
:focus {
  outline: none;
}

/* Section Seperator */
.section-seperator {
  border-bottom: 1px solid #edf0f2;
}

/*------------------------------------------------------------------
      [Service]
------------------------------------------------------------------*/
.service {
  background: #fff;
  padding: 30px;
}

.service .service-element,
.service .service-info {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.service .service-icon {
  display: block;
  font-size: 30px;
  color: #999caa;
  margin-bottom: 30px;
}

.service:hover .service-element {
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  -moz-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.service:hover .service-info {
  -webkit-transform: translate3d(0, -30%, 0);
  -moz-transform: translate3d(0, -30%, 0);
  transform: translate3d(0, -30%, 0);
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*------------------------------------------------------------------
      [Background Colors]
------------------------------------------------------------------*/
.bg-color-sky-light {
  background: #fafafa !important;
}

/*------------------------------------------------------------------
    [Row]
------------------------------------------------------------------*/
.row-space-2 {
  margin-right: -2px;
  margin-left: -2px;
}

.row-space-2>[class*="col-"] {
  padding-left: 2px;
  padding-right: 2px;
}

/*------------------------------------------------------------------
      [Content]
------------------------------------------------------------------*/
.content {
  padding-top: 40px;
  padding-bottom: 40px;
}

.content-sm {
  padding-top: 60px;
  padding-bottom: 60px;
}

.content-md {
  padding-top: 60px;
  padding-bottom: 60px;
}

.content-lg {
  padding-top: 60px;
  padding-bottom: 60px;
}

/*------------------------------------------------------------------
    [Full Width]
------------------------------------------------------------------*/
.full-width {
  width: 100%;
  height: auto;
}

/*------------------------------------------------------------------
    [Top margin]
------------------------------------------------------------------*/
.margin-t-40 {
  margin-top: 40px !important;
}

/*------------------------------------------------------------------
    [Bottom margin]
------------------------------------------------------------------*/
.margin-b-4 {
  margin-bottom: 4px !important;
}

.margin-b-10 {
  margin-bottom: 10px !important;
}

.margin-b-30 {
  margin-bottom: 30px !important;
}

.margin-b-40 {
  margin-bottom: 40px !important;
}

/*------------------------------------------------------------------
    [Top margin below 992px]
------------------------------------------------------------------*/
@media (max-width: 992px) {
  .md-margin-b-4 {
    margin-bottom: 4px !important;
  }

  .md-margin-b-30 {
    margin-bottom: 30px !important;
  }
}

/*------------------------------------------------------------------
    [Top margin below 768px]
------------------------------------------------------------------*/
@media (max-width: 768px) {
  .sm-margin-b-30 {
    margin-bottom: 30px !important;
  }

  .sm-margin-b-50 {
    margin-bottom: 50px !important;
  }
}
