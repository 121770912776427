h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
  color: #515769 !important;
  margin: 0 0 15px !important;
}

.normal {
  font-size: 22px !important;
  font-weight: normal !important;
}

.service-info {
  min-height: 120px !important;
}

.portfolio-img {
  max-width: 100% !important;
  max-height: 240px !important;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.fake-link {
  font-size: 16px !important;
}
